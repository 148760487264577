import { IBaseQuery } from "@/api/interfaces/IApi";
import { useStore } from "vuex";

interface IViewConfiguration extends IBaseQuery {
  activeFrom?: string;
  activeTo?: string;
}
export interface IBalanceOperation {
  amount: number;
  currency: string;
  operationType: string;
  description: string;
  paymentHistoryId: string | null;
}

export function useBalances(userId: number) {
  const store = useStore();
  async function getBalancesHistory(
    viewConfiguration: IViewConfiguration
  ): Promise<void> {
    const { success, message } = await store.dispatch("fetchBalanceHistory", {
      userId,
      viewConfiguration
    });
    if (!success && message) {
      await store.dispatch("showErrorNotification", message);
    }
  }

  async function getUserBalance(): Promise<void> {
    const { success, message } = await store.dispatch(
      "fetchUserBalance",
      userId
    );
    if (!success && message) {
      await store.dispatch("showErrorNotification", message);
    }
  }

  return {
    getBalancesHistory,
    getUserBalance,
  };
}
