<template>
  <Sidebar
    title="Credit balance history"
    :opened="balanceModalOpened"
    @hide="toggleBalanceModal(false)"
  >
    <template #heading>
      <AppCalendar @selectDate="selectDate" />
    </template>

    <ul class="balance-history-list">
      <li
        v-for="({ type, date, id, title, serviceType, amount },
        idx) in transactions"
        :key="id"
        class="balance-history-list--item"
        @click="openTransactionModal(id, type)"
      >
        <div class="row align-items-center">
          <div class="col-11">
            <AppTransaction
              :title="title"
              :date="date"
              :service-type="serviceType"
              :type="type"
              :amount="amount"
            />
          </div>

          <div class="col-1">
            <SvgIcon
              class="d-block ms-auto chevron-icon"
              icon="chevron-right"
            />
          </div>
        </div>

        <hr v-if="idx !== transactions.length - 1" />
      </li>
    </ul>

    <div
      v-if="transactions.length !== 0"
      class="overflow-button m-3 d-flex justify-content-center"
    >
      <Btn
        :disabled="transactions.length % perPage !== 0"
        type="primary"
        size="sm"
        @click="clickMoreHandler"
      >
        Show more
      </Btn>
    </div>
    <div v-else class="m-3 d-flex justify-content-center">
      <h2 class="user-information-title">History list is empty</h2>
    </div>
  </Sidebar>
  <TransactionDetailsModal
    :type="openedTransactionType"
    :opened="transactionModalOpened"
    :on-back="backTransactionModal"
    :inserted="true"
    v-bind="{ ...preparedProps }"
    @hide="toggleTransactionModal(false)"
    @sendReceipt="onSendReceipt"
  />
  <div class="user-balance content-box">
    <img :src="require('@/assets/img/money.svg')" alt="bg" />
    <div class="user-balance-wrapper">
      <h2>{{ title }}</h2>
      <h3 class="balance mt-5">
        <span class="currency-symbol">{{ currencySymbol }}</span> {{ balance }}
      </h3>
    </div>

    <div class="overflow-button">
      <Btn type="primary" size="sm" @click="clickHandler">
        Balance History
      </Btn>
    </div>
  </div>
</template>

<script>
import Btn from "@/components/ui/Buttons/Button";
import Sidebar from "@/components/ui/Modal/Sidebar/Sidebar";
import AppCalendar from "@/components/ui/Calendar/AppCalendar";
import AppTransaction from "@/components/BalanceHistory/AppTransaction";
import SvgIcon from "@/components/ui/SvgIcon";
import TransactionDetailsModal from "@/components/BalanceHistory/TransactionDetailsModal";
import {
  servicePackage,
} from "@/constants/serviceTypes";
import { incoming, outgoing } from "@/constants/transactionTypes";
import { useBalances } from "@/hooks/balances/useBalances";
import { useBalanceHelper } from "@/hooks/balances/useBalancesHelper";
import { useRoute } from "vue-router";
import { computed, defineComponent, ref, nextTick, watch } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "UserBalance",
  components: {
    TransactionDetailsModal,
    SvgIcon,
    AppTransaction,
    AppCalendar,
    Sidebar,
    Btn
  },
  props: {
    title: { type: String, default: "Credit Balance" },
    balance: { type: String, required: true },
    currencySymbol: { type: String, default: "$" }
  },

  setup(props) {
    const perPage = 10;
    const page = 1;
    const balanceModalOpened = ref(false);
    const transactionModalOpened = ref(false);
    const openedTransactionType = ref(null);
    const editModalOpened = ref(false);
    let editModalData = ref("");
    const balanceHistory = computed(() => store.getters.balanceHistory);
    let preparedProps = ref(null);
    const store = useStore();
    const router = useRoute();
    let viewConfiguration = ref({ page, perPage });
    const userId = Number(router.query.userId);
    const modalLoading = ref(false);

    watch(
      () => viewConfiguration,
      async () => {
        await getBalancesHistory(viewConfiguration.value);
      },
      { deep: true }
    );
    const { getBalancesHistory, addBalanceOperation } = useBalances(userId);
    const { getProps, formatTimestamp } = useBalanceHelper();
    const clickHandler = async () => {
      await getBalancesHistory(viewConfiguration.value);
      toggleBalanceModal();
    };

    const clickMoreHandler = () => {
      viewConfiguration.value.perPage =
        Number(viewConfiguration.value.perPage) + perPage;
    };

    const toggleBalanceModal = (status = true) => {
      balanceModalOpened.value = status;
      if (!status) {
        viewConfiguration.value.page = page;
        viewConfiguration.value.perPage = perPage;
        delete viewConfiguration.value.dateFrom;
        delete viewConfiguration.value.dateTo;
      }
    };
    const toggleTransactionModal = (status = true, type) => {
      transactionModalOpened.value = status;
      openedTransactionType.value = status ? type : null;
    };
    const toggleEditModal = (status = true) => {
      if (status) {
        editModalData.value = {
          amount: props.balance
        };
      } else {
        editModalData.value = "";
      }
      editModalOpened.value = status;
      nextTick(() => {
        toggleBalanceModal(!status);
      });
    };

    const openTransactionModal = (id = "", type) => {
      toggleBalanceModal(false);
      nextTick(() => {
        preparedProps.value = prepareProps(id);
        toggleTransactionModal(true, type);
      });
    };
    const backTransactionModal = () => {
      toggleBalanceModal(true);
      toggleTransactionModal(false);
    };

    const onSendReceipt = id => {
      console.log("Send receipt by transaction id", id);
    };
    const selectDate = data => {
      if (data?.activeFrom && data?.activeTo) {
        viewConfiguration.value.dateFrom = data?.activeFrom;
        viewConfiguration.value.dateTo = data?.activeTo;
      }
    };
    const submitEdit = async data => {
      modalLoading.value = true;
      await addBalanceOperation(data);
      modalLoading.value = false;
      nextTick(() => {
        toggleEditModal(false);
      });
    };

    const prepareProps = selectedId =>
      getProps(selectedId, balanceHistory.value);

    const transactions = computed(() =>
      balanceHistory.value?.map(
        ({
          id,
          amount,
          createdAt,
          description,
          operationType,
          paymentHistory,
          ...transaction
        }) => {
          const serviceType = servicePackage;
          const type = operationType === "writingoff" ? outgoing : incoming;
          const date = formatTimestamp(createdAt);
          description = description
            ? description
            : type === outgoing
            ? "Write-off of bonuses for payment of the package"
            : "Accrual of bonuses for the purchase of the package";
          const title =
            description + ` ${paymentHistory?.product?.profileName || ""}`;
          return {
            id,
            title,
            amount: "$" + amount,
            date,
            ...transaction,
            serviceType,
            type
          };
        }
      )
    );
    return {
      perPage,
      transactions,
      preparedProps,
      balanceModalOpened,
      transactionModalOpened,
      openedTransactionType,
      editModalOpened,
      modalLoading,
      editModalData,
      submitEdit,
      toggleEditModal,
      selectDate,
      clickMoreHandler,
      clickHandler,
      toggleBalanceModal,
      toggleTransactionModal,
      openTransactionModal,
      backTransactionModal,
      onSendReceipt
    };
  }
});
</script>

<style lang="scss">
$imageWidth: 233px;

.user-balance {
  position: relative;

  img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    height: 100%;
    width: auto;
    object-fit: contain;
  }
}

.user-balance-wrapper {
  min-width: 286px;
  width: calc(100% - #{$imageWidth});

  .balance {
    font-size: 4.3em;

    .currency-symbol {
      font-size: 0.67em;
    }
  }
}

.balance-history-list--item {
  cursor: pointer;

  @media (any-hover: hover) {
    .chevron-icon {
      transition: 0.25s;
    }

    &:hover {
      .chevron-icon {
        transform: translateX(3px);
      }
    }
  }
}
</style>
