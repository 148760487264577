<template>
  <Sidebar :title="null" v-bind="$props">
    <div
      :class="
        `transaction-details transaction-details--type-${serviceType} ${type}`
      "
    >
      <div class="d-flex justify-content-center align-items-center flex-column">
        <div
          :class="
            `transaction-details--icon d-flex justify-content-center align-items-center bg-${iconBg}`
          "
        >
          <svg-icon :icon="icon" />
        </div>

        <div class="text-center w-100 mt-3 pt-2">
          <strong>
            <b>{{ transactionTitle }}</b>
          </strong>

          <span class="title-capture mb-4 d-block">{{ date }}</span>

          <hr />

          <div class="pt-4">
            <div class="amount large-amount">
              <span>
                {{ mark }}
                {{ splitAmount[0] }}<span v-if="splitAmount[1]">.</span>
              </span>

              <span v-if="splitAmount[1]" class="small">
                {{ splitAmount[1] }}
              </span>
            </div>
            <p v-if="description" class="title-capture">{{ description }}</p>
          </div>

          <div class="mt-5 d-flex justify-content-center">
            <Btn
              type="danger"
              size="lg"
              @click="$emit('sendReceipt', transactionId)"
            >
              Send Receipt
            </Btn>
          </div>

          <p
            v-if="balanceAfterTransaction"
            class="small pt-4 d-flex justify-content-center"
          >
            <span class="secondary-text d-flex">
              <span class="d-block me-2">
                Credit balance after transaction:
              </span>
            </span>

            <strong>
              <b> {{ balanceAfterTransaction }}</b>
            </strong>
          </p>
        </div>
      </div>
    </div>
  </Sidebar>
</template>

<script>
import Sidebar from "@/components/ui/Modal/Sidebar/Sidebar";
import {
  serviceTypesValidator,
  transactionTypeValidator
} from "@/components/BalanceHistory/validators";
import SvgIcon from "@/components/ui/SvgIcon";
import Btn from "@/components/ui/Buttons/Button";
import { incoming } from "@/constants/transactionTypes";

export default {
  name: "TransactionDetailsModal",
  components: { Btn, SvgIcon, Sidebar },
  props: {
    serviceType: {
      type: String,
      validator: serviceTypesValidator,
      default: "package"
    },
    transactionDirection: { type: String, default: "" },
    transactionTitle: { type: String, required: true },
    date: { type: String, required: true },
    description: { type: String, required: false },
    transactionId: { type: String, required: true },
    amount: { type: String, required: true },
    type: {
      type: String,
      validator: transactionTypeValidator,
      default: "incoming"
    },
    balanceAfterTransaction: { type: String, required: false }
  },
  emits: ["sendReceipt"],
  computed: {
    mark() {
      return this.type === incoming ? "+" : "-";
    },
    icon() {
      return this.serviceType === "sms" ? "msg" : this.serviceType;
    },

    iconBg() {
      return this.serviceType === "package" ? "secondary" : "primary";
    },
    splitAmount() {
      return this.amount.split(".").map(a => a.trim());
    }
  }
};
</script>

<style scoped lang="scss">
.transaction-details--icon {
  font-size: 3.57rem;
  border-radius: 50%;
  width: 1em;
  height: 1em;
  min-width: 1em;
  min-height: 1em;

  .icon {
    font-size: 0.5em;
  }
}
</style>
